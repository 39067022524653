/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font; color: $text;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  tap-highlight-color: rgba(0,0,0,0);
}

.page-wrap {
  position: relative;
  overflow: hidden;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
    padding-top: 116px;
  }
}

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body { visibility: hidden; }
}

::selection {
  background-color: $selection;
  color: inherit;
}


/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block; vertical-align: middle;
  transition: $transitionDefault;
}

.icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
  width: 24px; position: absolute; left: 0;
  height: 1px; border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before, &:after { content: ""; margin-left: 0; }
  &:before { margin-top: 3px; }
  &:after { margin-top: 9px; }
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative; padding: 0 0 56.25%; height: 0; overflow: hidden; margin: 0 0 15px;

  iframe {
    position: absolute; top:0; left: 0; width: 100%; height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before, &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div { float: left; }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
	position: relative;
  cursor: pointer;
}

.block-link__target {
	&:before {
		content: ""; display: block;
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
	}
}


/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 0;
  background-color: $white;
  z-index: 999;
  width: 100%;
  transition: all 0.5s ease;
  @media #{$medium} {
    position: fixed;
    top: 0; left: 0;
    //overflow: hidden;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  float: left;
  max-width: 80%;

  img {
    transition: all 0.5s ease;
    display: block;
    height: 116px;
    width: 250px;
    line-height: 116px;
  }

  &:hover, &:focus {
    @include opacity(80);
  }
}

.main-nav {
  @include optimize-font; color: $black;
  margin-top: 0;

  a {
    text-decoration: none; color: $white; padding: 12px 15px;
    display: block; font-weight: 600;

  }

  a:hover, a:focus, li.active a {
    color: $black;
  }

  @media #{$medium} {
    margin: 0px 0 0 40px;
    float: right;

    li {
      float: left; margin: 0 0 0 20px;
    }

    a {
      border-top: 0; padding: 0;
    }
  }

  @media #{$large} {
    li {
      margin-left: 45px;
    }
  }
  &.open {
    position: fixed;
    pointer-events: auto;
    .main-nav__wrapper {
      display: flex;
      left: 0;
    }
    .main-nav__wrap {
      display: block;
    }
    .lang {
      display: block;
      left: auto; right: auto;
      margin-top: 0;
      span { display: none; }
      ul { position: relative; }
      li {
        display: inline-block;
        background-color: transparent;
        &.active {
          background-color: $white;
          a { color: $accent !important; }
        }
      }
    }
    .top-nav__wrap {
      display: block;
      text-align: center;
      margin: 0;
      padding: 30px 0 0;
      margin: 0 auto;
      max-width: 250px;
      border-top: 1px solid $white;
      ul {
        width: 100%;
      }
    }
    .icon-hamburger {
      transform: rotate(-45deg);
      transform-origin: center center;
      width: 20px;
      margin-top: 2px;
      border-color: $white;
      &::before {
        opacity: 1; width: 20px;
        margin-top: -3px;
        transform: rotate(90deg);
        transform-origin: center center;
        border-color: $white;
      }
      &::after {
        opacity: 0;
        bottom: 0;
        border-color: $white;
      }
    }
    &::after {
      right: 0;
    }
    @media (max-height: 600px) {
      .main-nav__trigger { top: 20px; }
    }
    .main-nav__wrap li a, .top-nav__wrap > ul li a { margin: 0 0 10px; }
  }

  @media #{$maxMedium} {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 999; pointer-events: none;
    padding-top: 20px; margin: 0;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0; left: 0; right: 100%; bottom: 0;
      background-color: $accent;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}

@media #{$medium} {
  .page-header.pinned {
    top: -42px;
    .page-header__logo img {
      line-height: 76px;
      height: 76px;
      width: 150px;
      margin-top: 40px;
    }

  }
}

.main-nav__trigger {
  display: block; position: relative; padding: 10px 12px 10px 12px;
  background-color: transparent; color: $black; font-weight: 500;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
  position: absolute; top: 60px; right: 20px; z-index: 99999999;
  margin-top: 0; pointer-events: auto;

  span {
    display: none;
  }

  .icon-hamburger {
    position: absolute; left: 0px; top: 6px;
  }

  &:hover, &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none; margin: 0 0 15px;

  a {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0; right: 100%; bottom: -2px;
      height: 1px;
      background-color: $white;
      transition: all 0.5s ease;
    }
  }
  li:hover, li.active {
    a:after, a {
      right: 0; color: $white;
    }
  }
  li { pointer-events: auto; }
  li.mobile {
    display: inline-block;
    @media #{$medium} {
      display: none;
    }
  }

  @media #{$medium} {
    margin: 0 0 0 -20px; display: block;
    padding: 30px 0 30px 20px;
    position: relative;
    &::before {
      content: '';
      display: block;
      background-color: $accent;
      position: absolute;
      top: -40px; left: 0; right: -9999px; bottom: 0;
      z-index: -2;
    }
  }
}

.main-nav__wrapper {
  @media #{$maxMedium} {
    position: absolute;
    top: 50%; left: -200px;
    width: 100%; z-index: 9999;
    transform: translateY(-50%);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    //padding-top: 80px;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    li a { color: $white; text-align: center; padding: 5px 0; margin-bottom: 20px; display: inline-block; }
    .lang {
      position: relative;
      text-align: center;
      left: 0; right: 0;
      width: 100%; z-index: 9999;
      margin-top: 30px;
      ul { display: inline-block; }
      li a { color: $white !important;
        padding: 5px 10px; border-color: $white;
        &:after { border-color: $white; }
      }
    }
  }
}

.share {
  margin-top: 20px;
  span { @include fs(24); color: $black; }
  i { margin-right: 10px; @include fs(20); }
}

.top-nav__wrap {
  display: none;
  margin: 0 0 0 -20px;
  padding: 12px 0 12px 20px;
  position: relative;
  transition: height 0.5s ease;
  &::before {
    content: '';
    display: block;
    background-color: transparent;
    position: absolute;
    top: 0; left: 0; right: -9999px; bottom: 0;
    z-index: -1;
    @media #{$medium} {
      background-color: lighten($accent, 10%);
    }
  }
  ul {
    margin-bottom: 0;
    display: inline-block;
    float: left;
  }
  @media #{$medium} {
    display: block;
  }
}

.lang {
  padding: 0;
  margin-right: 0;
  display: none;
  position: relative;
  float: right;
  @media #{$medium} {
    display: inline-block;
    position: relative;
    span { display: block; color: $white !important; @include fs(15); line-height: 1em; &:after { border-top: 5px solid $white !important; } }
  }
  &:after, &:hover:after {
    display: none;
  }
  ul {
    position: absolute;
    display: none;
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    z-index: 99;
    li {
      display: block;
      float: none;
      margin-bottom: 7.5px;
      margin-left: 0!important;
      padding: 0;
      cursor: pointer;
      width: 42px;
      border-bottom: none!important;
      transition: all 0.5s ease;
      background-color: $white;
      a { color: $accent; }
      @media #{$medium} {
        //background-color: $accent;
      }
      &:after, &:hover:after {
        background: none;
        opacity: 0;
        border: none;
        display: none;
        visibility: hidden;
        transition: all 0s ease;
      }
      &:hover, &.active {
        background-color: darken($accent, 5%);
        a { color: $white; }
      }
    }

    a {
      color: $accent;
      padding: 12px 8px;
      display: inline-block;
      margin-bottom: 0;
      cursor: pointer;
      @include fs(15);
      @media #{$medium} {
        @include fs(13);
      }
      @media #{$large} {
        color: $white;
        @include fs(14);
      }
    }
  }
  &:after, &:hover:after {
    background: none;
    opacity: 0;
  }
  span.active {
    color: $white;
    cursor: pointer;
    font-weight: 500;
  }
  a {
    color: $white;
    text-decoration: none;
  }
  span.active:after {
    display: inline-block;
    position: relative;
    content: '';
    width: 0;
    height: 0;
    top: -3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $white;
    margin-left: 5px;
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}


/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  //padding: 20px 0;

  @media #{$medium} {
    padding: 0 0 150px;
  }
}


/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  padding: 50px 0;

  .grid__item {
    margin-bottom: 20px;
  }

  .pre-title {
    margin-top: 0;
  }

  &.blue-footer {
    background-color: $accent;
    color: $white;
    i, span, a { color: $white; }
    .pre-title {
      border-color: $white;
    }
    a { text-decoration: none; }
    a.esign { background-image: url("../images/logo-esign-white.svg"); }
    h2 { border-color: $white; }
    .space-border { background-color: $white; }
  }

  span { @include fs(18); color: $black; a { display: block; } }
  i { margin-right: 10px; @include fs(20); }

  .grid__item:last-child {
    i { @include fs(24); }
  }

  @media #{$small} {
    .grid__item:first-child a:last-child {
      margin-left: 72px;
    }
    span a { display: inline-block; }
  }

  @media #{$medium} {
    height: 243px;
    margin: -243px 0 0;
    z-index: 5;
    span { @include fs(20); }
    .grid {
      margin-bottom: 20px;
    }
  }
  @media #{$large} {
    span { @include fs(24); }
    .grid {
      margin-bottom: 50px;
    }
  }
}

.space-border {
  width: 0px;
  height: 1px;
  background-color: $black;
  display: block;
  vertical-align: middle;
  @media #{$large} {
    display: inline-block;
    width: 70px;
    margin: 0 50px;
  }
}

a.esign {
  width: 80px; height: 20px;
  background-image: url("../images/logo-esign.svg"); background-size: 77px 20px; background-repeat: no-repeat; background-position: top left;
  backface-visibility: hidden; transform: translate3d(0,0,0); text-indent: -9999px;
  opacity: 0.6; transition-duration: 0.5s;
  display: block; outline: 0; z-index: 9999;
  &:hover, &:focus { opacity: 1; }
  @media #{$medium} {
    width: 16px; position: absolute; right: 45px; bottom: 0;
    &:hover, &:focus { opacity:1; width: 80px; }
  }
}


/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none; display: inline-block;
  }
}


// Article
section { position: relative; }

article {
  @include clearfix; margin: 0 0 20px;

  a {
    img { display: block; @include opacity(100); }

    &:hover, &:focus {
      img { @include opacity(80); }
    }
  }
}

.article-detail {

}

.article-detail__image {
  margin-bottom: 1.5em;
}


// Note
.note {
  @include clearfix;
  margin: 0 0 20px; padding: 15px 20px 0;
  background: #efefef;

  ul, p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}


// Slideshow
.cycle-slideshow {
  width: 100%; position: relative;
}

.cycle-pager {
  width: 100%; text-align: center;

  span {
    display: inline-block; margin: 0 3px; font-size: 36px;
    height: 20px; width: 20px; line-height: 14px;
    cursor:pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden; text-indent: -500px;
    transition: $transitionDefault;

    &:hover, &:focus {
      transition: none;
    }
  }

  span:hover, span:focus, .cycle-pager-active {
    text-indent: 0;
  }
}


// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}


// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px; width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3C5B9B;
  &:hover, &:focus {
    background-color: darken(#3C5B9B, 8%);
  }
}

.social__link--twitter {
  background-color: #2DAAE1;
  &:hover, &:focus {
    background-color: darken(#2DAAE1, 8%);
  }
}

.social__link--pinterest {
  background-color: #CB2027;
  &:hover, &:focus {
    background-color: darken(#CB2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173B2;
  &:hover, &:focus {
    background-color: darken(#0173B2, 8%);
  }
}


/* =============================================================================
   PAGES
   ========================================================================== */

/* ------------------------*/ /* ---------->>> HOME <<<-----------*/ /* ------------------------*/

.hero-header {
  //background-image: url("../images/home-header.jpg");
  //background-size: 100%;
  //background-position: top;
  //background-attachment: fixed;
  //display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 300px;
  position: relative;
  .button {
    position: absolute;
    bottom: -30px;
    left: -20px;
  }
  @media #{$medium} {
    margin-left: 20px;
    height: 500px;
  }
  //margin-left: 20px;
  @media #{$large} {
    margin-left: 40px;
  }
  @media (min-width: 1480px) {
    margin-left: calc((65px + (100vw - 1480px))/2);
    width: calc(100% - (65px + (100vw - 1480px))/2);
  }
}

.button {
  background-color: $accent;
  padding: 20px 35px 20px 25px;
  color: $white;
  @include fs(14);
  @media #{$medium} {
    padding: 25px 35px 25px 25px;
  }
}


.sub-nav__wrap {
  display: inline-block; position: relative;
  ul {
    margin: 0; padding: 0; list-style: none; display: inline-block;
    position: absolute; bottom: 0; white-space: nowrap;
  }
  li {
    display: block;
    margin: 0;
    background-color: $accent;
    transition: all 0.5s ease;
    &.active, &:hover, &:focus, &:active {
      background-color: lighten($accent, 12%);
    }
  }
  a {
    color: $white;
    margin: 0;
    text-decoration: none;
    padding: 20px 20px;
    display: inline-block;
  }
  @media #{$medium} {
    display: block;
    transform: translateY(-50%) translateX(-20px);
    margin-left: 0;
    ul { position: static; bottom: auto; }
    li {
      display: inline-block;
      float: left;
    }
    a { padding: 30px 20px; }
  }
  @media #{$large} {
    a {
      padding: 30px 30px;
    }
  }
}

section, .section {
  margin-bottom: 50px;
  position: relative;
  @media #{$small} {
    margin-bottom: 100px;
  }
  @media #{$medium} {
    margin-bottom: 150px;
  }
}

.about-us {
  margin-top: 50px;
}

.what-we-do {
  .grid {
    margin-bottom: 0;
  }
  //.img-with-bg {
  //  width: 100%;
  //  padding-bottom: 50%;
  //  position: relative;
  //  img {
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    @include object-fit(cover, center);
  //  }
  //}
}

.grid-button {
  opacity: 1;
  position: absolute;
  @include fs(16);
  //background-color: $accent;
  width: 90%;
  display: block;
  color: $white;
  padding: 15px;
  text-decoration: none;
  margin: -55px 15px 10px -15px;
  text-align: center;
  z-index: 10;
  //font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  i { @include fs(12); }
  transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: $accent;
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: -1;
  }
  @media #{$medium} {
    opacity: 0;
    padding-left: 0;
    &::after {
      right: 100%;
    }
  }

}

.what-we-do, .realisations__wrapper {
  a:hover .grid-button {
    opacity: 1;
    padding-left: 15px;
    &::after {
      right: 0;
    }
  }
  .grid__item { position: relative; &:last-child { margin-bottom: 0; }}
  a { width: 100%; margin-bottom: 10px; }
  @media #{$medium} {
    a { margin-bottom: 0; }
  }
}

.realisations__wrapper {
  .grid {
    margin-left: -40px;
    text-align: center;
  }
  .grid__item {
    padding-left: 40px;
    margin-bottom: 40px;
  }
  @media #{$small} {
    .grid__item:nth-child(2n + 2) {
      position: relative;
      top: 30px;
    }
  }
  @media #{$large} {
    .grid__item:nth-child(2n + 2) {
      top: 0;
    }
    .grid__item:nth-child(3n + 2){
      position: relative;
      top: 30px;
    }
  }
  a.go-link {
    clear: both;
    display: inline-block;
    margin: 0 auto;
    width: auto;
    text-align: center;
  }
}

.slick__header {
  height: 100%;
  .slick-slide, .slick-slide > div, .slick-list, .slick-track {
    height: 100%;
  }
}

.realisations {
  .grid-button {
    width: 80%;
  }
  select {
    height: 43px;
    color: $accent;
    text-indent: 5px;
    padding: 0 10px;
    border: 1px solid $accent;
  }
}

.realisations__header {
  @media #{$medium} {
    //.pre-title__wrapper { width: 70%; }
  }
  form {
    width: 100%;
    margin-bottom: 30px;
    @media #{$medium} {
      width: 200px; float: right;
      margin-bottom: 0;
    }
  }
}

.hero-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

div.page-offset {
  padding-top: 30px;
  @media #{$medium} {
    padding-top: 100px;
  }
}

section.page-offset {
  padding-top: 30px;
  @media #{$medium} {
    padding-top: 60px;
  }
}

.special-grid__item {
  background-color: $grayLight;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media #{$small} {
    height: calc(100% - 30px);
  }
  @media #{$medium} {
    padding: 0;
    height: calc(100% - 20px);
  }
  div {
    width: 75%;
    p { margin-bottom: 10px; }
  }
}

.square-img {
  width: 100%;
  padding-bottom: 80%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.go-link {
  @include fs(12);
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;

  // @include smart-underline; cross browser text-decoration skip

  &::after {
    content: '';
    display: inline-block;
    width: 50px;
    height: 6px;
    margin-right: 15px;
    border-bottom: 1px solid $linkColor;
    float: left;
    transition: $transitionDefault;
  }
  &:hover {
    letter-spacing: 2px;
    background-color: transparent;
    &::after {
      width: 70px;
    }
  }
}

.go-link--nobar {
  &::after {
    display: none;
  }
}

h2 + .h1 {
  display: block;
  width: 100%;
  @media #{$small} {
    display: inline-block;
    width: calc(100% - 120px);
  }
  @media #{$medium} {
    margin-bottom: 60px;
  }
}

@media #{$medium} {
  .mt-50 {
    margin-top: 50px;
  }
}

.job__list {
  list-style: none;
  margin: 0;
  li {
    background-color: #F8F8FA;
    padding: 10px;
    margin: 10px 0;
  }
  a.go-link {
    float: right;
    margin-top: 8px;
    //padding: 15px;
  }
  a:not(.go-link){
    color: $accent;
    text-decoration: none;
    font-weight: 500;
    padding-left: 10px;
  }
}

.job__description {
  padding: 0;
  @media #{$small} {
    padding: 0 0 0 80px;
  }
  @media #{$medium} {
    padding: 0 60px 0 80px;
  }
}

.jobs-detail {
  .grid__item {
    margin-bottom: 50px;
  }
}

.job__side-panel {
  background-color: $accent;
  color: $white;
  padding: 50px 0 50px 50px;
  position: relative;
  .h3 {
    color: $white;
  }
  ul { list-style: square; margin-bottom: 0; }
  li { font-weight: 300; @include fs(16); }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; right: -99999px; bottom: 0;
    background-color: $accent;
    z-index: -1;
  }
}

blockquote {
  color: $accent;
  display: inline-block;
  width: auto;
  position: relative;
  margin-bottom: 0;
  padding: 0;
  border: none;
  font-style: normal;
  padding-bottom: 20px;
  @include fs(30);
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 1px;
  margin: 50px 0;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $accent;
  }
}

.img-with-bg {
  position: relative;
  width: calc(100% + 20px);
  margin-bottom: 30px;
  margin-left: -20px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    padding-bottom: 50%;
    right: -20px; bottom: -20px;
    z-index: -1;
    background-color: $grayLight;
  }
  &.special {
    margin-top: -150px;
    width: calc(100% + 10px);
    margin-left: -5px;
    + blockquote {
      margin-top: 0;
    }
    @media #{$small} {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: -150px;
      + blockquote {
        margin-top: 0;
      }
    }
    @media #{$medium} {
      margin: 0;
      + blockquote {
        margin-top: 70px;
      }
    }
    @media #{$large} {
      top: - 150px;
      + blockquote {
        margin-top: -70px;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: -15px; bottom: -15px;
      left: -15px; top: -15px;
      z-index: -1;
      background-color: $white;
      @media #{$small} {
        right: -20px; bottom: -20px;
        left: -20px; top: -20px;
      }
    }
  }
  &.double {
    position: absolute;
    width: 50% !important;
    top: 0;
    right: 0;
    z-index: 99;
    &::after {
      display: none;
    }
    @media #{$medium} {
      top: -50px;
    }
  }
  @media #{$small} {
    width: 100%;
    margin: 0 0 30px 0;
  }
  @media #{$medium} {
    width: 90%;
    margin: 0;
  }
  .absolute-positioned {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
    overflow: hidden;
    display: block;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @include object-fit(cover, center);
    }
    i {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $white;
      @include fs(30);
    }
  }
}

.double-images {
  position: relative;
  padding-top: 30px;
  .special {
    margin-top: 0;
  }
  @media #{$medium} {
    margin-top: -30px;
  }
}

.contact-maps {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
  }
  @media #{$medium} {
    height: 350px;
  }
  img.mobile {
    display: block;
    @media #{$medium} {
      display: none;
    }
  }
  img.desktop {
    display: none;
    @media #{$medium} {
      display: block;
    }
  }
}

.contact .contact form, .contact-info {
  margin-top: 40px;
}

.contact > .grid > .grid__item {
  padding-left: 5vw;
}

.contact > .grid {
  margin-left: -5vw;
}

.contact-info {
  a {
    text-decoration: none;
    @include fs(22);
    font-weight: 500;
    line-height: 1.6em;
  }
  i { margin-right: 20px; }
}

.contact-balk {
  width: 100%;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: $accent;
    z-index: -1;
  }
  > div:first-child {
    width: 100%;
  }
  .slider-img {
    width: 100%;
    padding-bottom: 75%;
  }
  .a-c {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 30px;
  }
  p { color: $white; }
  a { color: $white; &:after { border-color: $white; } }
  @media #{$small} {
    .a-c {
      padding: 60px 40px;
    }
    .slider-img {
      padding-bottom: 60%;
    }
  }
  @media #{$medium} {
    > div:first-child {
      width: auto;
    }
    > div:last-child {
      width: calc(100% - 450px);
    }
    .slider-img {
      width: 450px;
      height: 100%;
      padding-bottom: 0;
    }
    &:after {
      right: -9999px;
    }
  }
  @media #{$large} {
    .a-c {
      padding: 60px 80px;
    }
  }
}

.slider {

}

.slick__wrapper {
  position: relative;
  margin-bottom: 20px;
  .slick-slide {
    //margin: 0 10px 0 0;
    padding: 3px;
    transition: all 0.5s ease;
    a {
      display: block !important;
    }
  }
  .square-img { margin-bottom: 0; }
  .slick-slide:hover, .slick-slide.selected {
    background-color: $accent;
  }
  @media #{$small} {
    .slick-slide {
      padding: 5px;
    }
  }
  @media #{$medium} {
    max-width: calc(100% - 45px);
    margin-top: 70px;
  }
}

.slider-img {
  width: 100%;
  padding-bottom: 80%;
  background-size: cover;
  background-position: center;
  @media #{$small} {
    padding-bottom: 60%;
  }
}

.slick-arrow {
  background-color: $accent;
  padding: 5px 18px;
  color: $white;
  position: absolute;
  top: 50%;
  @include fs(30);
  z-index: 99;
  cursor: pointer;
}

.slick-arrow--right {
  right: 0; transform: translateX(50%) translateY(-50%);
  i { margin-right: -5px; }
}
.slick-arrow--left {
  left: 0; transform: translateX(-50%) translateY(-50%);
  i { margin-left: -5px; }
}

.slider__wrapper {
  position: relative;
  margin-top: 40vw;
  @media #{$small} {
    margin-top: 30vw;
  }
  @media #{$medium} {
    margin-top: 60px;
  }
}

.floating-div {
  position: absolute;
  top: 0; right: 0;
  background-color: $white;
  padding: 20px 0 20px 20px;
  width: 90%;
  //z-index: 5;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px; left: -20px;
    width: 90%;
    padding-bottom: 90%;
    background-color: $grayLight;
    z-index: -1;
  }
  @media #{$small} {
    top: -25vw; bottom: auto;
    padding: 40px 0 40px 40px;
    .go-link {
      margin-left: 90px;
    }
    &::before {
      width: 50%;
      padding-bottom: 50%;
    }
  }
  @media #{$medium} {


  }
  @media #{$large} {
    padding: 40px 0 40px 100px;
    width: 50%;
    top: -40px;
  }
}

.checkbox {
  margin-bottom: 20px;
  p { margin-right: 20px; color: $gray; font-weight: 300; }
  label {
    //margin-left: 40px; margin-right: 20px;
    margin: 0 20px 30px 60px;
    padding: 12px 0;
    position: relative;
    color: $text; font-weight: 300;
  }
  input[type="checkbox"]{
    position: absolute;
    z-index: -9999;
    opacity: 0;
    // ~ span {
    //   display: inline-block; position: absolute; margin-left: -60px; color: transparent; @include fs(22);
    //   padding: 10px 13px; background-color: $grayLight; top: 50%; transform: translateY(-50%);
    // }
    &:checked {
      ~ span {
        color: $black;
      }
    }
  }
  @media #{$larger}{
    margin-bottom: 0;
  }
}


/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page { margin: 2cm 0.5cm 1cm; }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0; overflow: visible; direction: inherit;
  }

  a, a:visited {
    text-decoration: underline;
  }

  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }

  .page-footer, .main-nav { display: none; }

  .medium--one-half { width: 50%; }
  .medium--two-thirds { width: 66.66%; }
  .medium--one-third { width: 33.33%; }
  .medium--three-quarters { width: 75%; }
  .medium--one-quarter { width: 25%; }
}

// CUSTOM PAGES & BLOCKS

.custom-page__title {
  margin: 40px 0 20px;
}

.custom-page__intro {
  font-weight: 700;
  font-size: 20px;
  color: $text;
}

.flex-wrap {
  display: flex;
  flex-flow: row wrap;

  & > * {
    width: 100%;
  }
}

.block__text {
  color: $text;
}

.block__image {
  display: flex;
  width: 50%;
  margin-bottom: 40px;

  img {
    display: block;
    width: 100%;
    max-height: 450px;
    @include object-fit(cover, center);

    @media #{$medium} {
      max-height: 600px;
    }

    @media #{$large} {
      max-height: none;
    }
  }

  &.image-left {
    justify-content: flex-start;
  }

  &.image-right {
    justify-content: flex-end;
  }
}

.block__cta {
  .button {
    display: inline-block;
    float: none;
    margin: 20px 0 40px;
  }
}

.block__caption {
  font-size: 24px;
  color: $gray;
  margin-bottom: 40px;
}

.block__image + .block__text,
.block__text + .block__image,
.block__image + .block__image {
  @media #{$medium} {
    padding-left: 40px;
  }
}

.block__image, .block__text {
  @media #{$maxMedium} {
    width: 100% !important;
  }
}

// cookies
.cookie-notification {
  position: fixed;
  bottom: 30px;
  left: 30px;
  right: 30px;
  z-index: 10000;
  opacity: 1;
  transition: 0.4s $easeOut;

  &.active {
    opacity: 1;
  }

  @media #{$medium} {
    width: 520px;
    right: inherit;
  }
}

.cookie-notification__content {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  padding: 30px;
  max-height: 40vh;
  overflow-y: auto;

  .button-group {
    button {
      color: white;
    }
    a {
      color: gray(700);

      &:hover,
      &:focus {
        color: gray(800);
      }
    }
  }

  @media #{$medium} {
    flex-wrap: wrap;
  }
}

.cookie-notication__form {
  margin: 60px 0 0;
}

form {
  .grid {
    margin-bottom: 0;
  }
}

fieldset {
  margin: 15px 0;
}

legend {
  font-size: rem(12px);
  font-weight: 600;
  margin-bottom: 1em;
  text-transform: uppercase;
}

input,
select,
textarea,
button,
.button,
label,
.label {
  margin: 0;
  font-size: 16px;
  vertical-align: middle;
  color: #000000;
}

button,
input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

label,
.label {
  display: block;
  margin-bottom: 8px;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
textarea,
select {
  appearance: none;
  padding: 0 15px;
  display: block;
  background: $white;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: 1px solid gray(400);

  &:focus {
    outline: 0;
    border-color: gray(900);
  }
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  height: 185px;
  min-height: 45px;
  max-height: 600px;
  line-height: 1.5em;
  padding: calc((45px - 1.5em) / 2) 15px;
}

select {
  cursor: pointer;
  border-radius: 10px;
  background: $white url("../images/chevron-down.svg") center right 15px no-repeat;
  background-size: 12px 6px;
  padding-right: 35px;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  margin: 3px 0;
  cursor: pointer;
}

input[type="file"] {
  background-color: $white;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  appearance: textfield;
  // appearance: none; // Remove the rounded corners
  box-sizing: content-box;
}

.button,
select {
  &:focus {
    outline: 0;
    box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1);
  }
}

::placeholder {
  color: gray(900);
  font-style: italic;
}

// Hide clear text field ie 10+
::-ms-clear {
  display: none;
}

// Hide the cancel button
::-webkit-search-cancel-button {
  appearance: none;
}

// Hide the magnifying glass
::-webkit-search-results-button {
  appearance: none;
}

// Hide number input spinner
::-webkit-inner-spin-button {
  appearance: none;
}
::-webkit-outer-spin-button {
  appearance: none;
}

.checkbox,
.radio {
  position: relative;

  label {
    padding: 3px 0 0 30px;
    margin: 0 20px 3px 10px !important;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 1px solid gray(400);
      background-color: #fff;
    }

    &:after {
      top: 2px;
      left: 6px;
      border: solid $black;
      border-width: 0 3px 3px 0;
      width: 8px;
      height: 13px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 1px;
    z-index: 111;
    outline: 0;

    &:focus + label {
      &:before {
        border-color: gray(900);
      }
    }

    &:checked + label {
      &:before {
        border-color: gray(900);
      }

      &:after {
        opacity: 1;
      }
    }

    &:disabled {
      + label {
        cursor: not-allowed;

        &:after {
          opacity: 0.5;
        }
      }
    }
  }
}

.checkbox--toggle {
  input[type="checkbox"] {
    &:focus,
    &:active {
      + label {
        &:before {
          border: 1px solid gray !important;
        }
      }
    }

    &:disabled {
      + label {
        &:before {
          opacity: 0.5;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    + label {
      padding-left: 55px;
      user-select: none;

      &:focus,
      &:active {
        outline: none;
      }

      &:before {
        position: absolute;
        display: inline-block;
        width: 40px;
        height: 20px;
        background: gray !important;
        border-radius: 20px;
        left: 0;
        border: 0;
        top: 0;
        transition: background-color $transitionDefault;
      }

      &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $white;
        top: 2px;
        z-index: 1;
        left: 2px;
        opacity: 1;
        transform: none;
        transition: all $transitionDefault;
        border-width: 0;
        border-color: gray(500);
      }
    }

    &:checked {
      + label {
        &:before {
          background: $accent !important;
        }
        &:after {
          left: 22px;
        }
      }
    }
  }
}

.checkbox__description {
  margin-left: 55px !important;
  display: block;
  background: transparent !important;
  font-size: 14px !important;
}

.radio {
  label {
    &:before,
    &:after {
      border-radius: 100%;
    }

    &:after {
      border: 0;
      transform: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background-color: $black;
    }
  }
}

// Input group
.input-group {
  margin: 10px 0 20px;

  + fieldset {
    margin-top: 30px;
  }
}

.input-group--horizontal {
  display: flex;

  .radio,
  .checkbox {
    label {
      margin-bottom: 0;
    }
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-left: 20px;
  }
}

.input-group--error {
  input,
  textarea,
  select {
    border-color: $error;
  }

  .checkbox,
  .radio {
    label:before {
      border-color: $error;
    }
  }
}

.input-group__error {
  color: $error;
  font-size: rem(14px);
  display: block;
  margin-top: 10px;
}

.required {
  display: inline-block;
  color: $error;
}

// Recaptcha
.grecaptcha-badge {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: layer("modal");
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  &:not(.modal--active):not(.modal--before-show) {
    pointer-events: none;
  }

  &:not(.modal--fullscreen) {
    -webkit-overflow-scrolling: touch;
  }

  &[data-background-scroll="true"] {
    overflow-y: auto;
  }
}

.modal--active {
  visibility: visible;
  opacity: 1;
  z-index: layer("modal") + 1;
}

.modal--fullscreen {
  background: $white;

  .modal__dialog {
    margin: 0;
    max-width: none;
    height: 100vh;
    display: flex;

    div[role="document"] {
      margin: auto;
      padding: $gutter * 2 0;
    }
  }

  .modal__close {
    position: fixed;
    margin: 0;
    top: $gutter;
    right: $gutter;

    @media #{$maxMedium} {
      top: 38px; // align with menu toggle
    }
  }
}

.modal--before-show {
  visibility: visible;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.modal--before-hide {
  pointer-events: none;
}

.modal--fade {
  &.modal--before-show {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }

  &.modal--before-hide {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
}

.modal__va-wrap {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.modal__va-m {
  display: table-cell;
  vertical-align: middle;
}

.modal__dialog {
  background-color: $white;
  max-width: 100%;
  margin: 30/2;
  position: relative;
  outline: 0;

  @media #{$medium} {
    max-width: 900px;
    margin: 30 * 2 auto;
  }

  div[role="document"] {
    padding: 30 0;
    width: 100%;

    @media #{$large} {
      padding: 30 * 2 0;
    }
  }
}

.modal__close {
  position: absolute;
  top: 30/3;
  right: 30/3;
  margin: 30/2;
  background: transparent;
  outline: none;
  transition: none;

  svg {
    transition-property: fill;
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: $accent;
    }
  }

  &:focus {
    svg {
      fill: $accentHover;
    }
  }

  &:active {
    svg {
      fill: $accent;
    }
  }
}

.js-modal-trigger * {
  pointer-events: none;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
  margin-left: -30px;

  .button,
  a {
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

.button-group--align-center {
  align-items: center;
}
